
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ApiCalls from '@/services/api-calls';
import Item from '@/components/Item.vue';
import Pagination from '@/components/Pagination.vue';
import Loader from '@/components/Loader.vue';
import { ItemDisplay } from '@/models';
import store from '@/store';

const enum EnumSearchType {
  ITEMS = 'ITEMS',
  MEDIA = 'MEDIA',
}

@Options({
  components: { Item, Pagination, Loader },
})
export default class Search extends Vue {
  api = new ApiCalls();

  selectedSearchType = EnumSearchType.ITEMS;

  categories: {
    id: number,
    label: string,
    properties: {
      id: number,
      label: string,
    }[],
  }[] | null = null;

  selectedCategoryId: number | null = null;

  properties: { id: number, label: string }[] = [];

  fieldComparisonTypes = [
    { type: 'eq', label: 'is exactly', label_GR: 'είναι ακριβώς' },
    { type: 'neq', label: 'is not exactly', label_GR: 'δεν είναι ακριβώς' },
    { type: 'in', label: 'contains', label_GR: 'περιέχει' },
    { type: 'nin', label: 'does not contain', label_GR: 'δεν περιέχει' },
    { type: 'ex', label: 'has any value', label_GR: 'έχει τιμή' },
    { type: 'nex', label: 'has no value', label_GR: 'δεν έχει τιμή' },
    { type: 'res', label: 'is this RDF resource (by ID)', label_GR: 'είναι το RDF με ID' },
    { type: 'nres', label: 'is not this RDF resource (by ID)', label_GR: 'δεν είναι το RDF με ID' },
  ];

  textSearch = '';

  searchByValuesParams: {
    joiner?: 'and' | 'or',
    propertyId: number,
    type: string,
    text: string,
  }[] = [];

  items: ItemDisplay[] | null = null;

  pagination = {
    currentPage: 0,
    itemsCount: 0,
  };

  isLoading = false;

  created(): void {
    this.addValueParams();

    this.categories = store.getters.getCategories;
  }

  @Watch('$store.getters.getCategories', { deep: true, immediate: true })
  onCategoriesInit(categories: any): void {
    this.categories = categories;
  }

  @Watch('selectedCategoryId')
  onSelectedCategoryIdChange(selectedCategoryId: number) {
    this.properties = this.categories?.find((x) => x.id === selectedCategoryId)?.properties || [];
    console.log('p', this.properties);
  }

  addValueParams(): void {
    this.searchByValuesParams.push({ propertyId: 0, type: 'eq', text: '' });
  }

  onSearch(): void {
    this.getItems();
  }

  onSelectPage(page: number): void {
    this.getItems(page);
  }

  getItems(page = 0) {
    this.items = [];
    this.isLoading = true;
    const params: {
      type: 'items' | 'media',
      search: string,
      valueParams: {
        joiner?: 'and' | 'or',
        propertyId: number,
        type: string,
        text: string,
      }[]
    } = {
      type: this.selectedSearchType === EnumSearchType.ITEMS ? 'items' : 'media',
      search: this.textSearch,
      valueParams: this.searchByValuesParams,
    };

    this.api.search(params, store.getters.getItemsPerPage, page).then((response) => {
      console.log('results', response);
      this.pagination = {
        currentPage: page,
        itemsCount: response.itemsCount,
      };
      this.items = response.items.map((x: any) => ({
        id: x['o:id'],
        title: x['o:title'],
        thumbnail: x.thumbnail_display_urls.square,
      }));
      this.isLoading = false;
    });
  }

  onSelectItem(id: number): void {
    this.$router.push(`/${this.selectedSearchType === EnumSearchType.ITEMS ? 'item' : 'media'}/${id}`);
  }
}
